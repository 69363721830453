import React from "react";
import WebsiteContainer from './../components/site-pages/websiteContainer';


const NotFound = () => {
  return (
    <WebsiteContainer>
        <div className="text-center mobile-box">
          <img src="/assets/images/404.png" style={{width:'50%'}}/>
        </div>
    </WebsiteContainer>
  );
};

export default NotFound;
